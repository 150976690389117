const URL_INSURAMA = process.env.VUE_APP_INSURAMA_API_URL

var buildUrl = function(base, path) {
    return `${base}/${path}`
}

var buildUrlInsurama = function(path) {
    return buildUrl(URL_INSURAMA, path)
}

export default {
    /*STORES: buildUrlInsurama('stores'),
    evaluation: id => buildUrlInsurama(`proposals/${id}/evaluation`),*/
    DEVICE_MODELS: buildUrlInsurama('models'),
    CONTACT_POST: buildUrlInsurama('contacto-web'),
    //UPDATE_CURRENT_PROCESS: (id) => buildUrlInsurama(`proposals/${id}`),
}