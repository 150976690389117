export default {
    seguromovil: {
        "name": "seguromovil",
        "product_type_id": "1"
    },
    seguropatinete: {
        "name": "seguropatinete",
        "product_type_id": "3"
    },
    seguromultidispositivo: {
        "name": "seguromultidispositivo",
        "product_type_id": "5"
    },
    seguroportatil: {
        "name": "seguroportatil",
        "product_type_id": "6"
    },
    segurosmarttv: {
        "name": "segurosmarttv",
        "product_type_id": "7"
    },
    extensiondegarantia: {
        "name": "extensiondegarantia",
        "product_type_id": "4"
    },
    insurama: {
        "name": "insurama",
        "product_type_id": "0"
    }
    //Tusegurotranquilidad: product_type_id: 2
    //Tuseguromascota: product_type_id: 7
}