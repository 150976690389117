import pageRoutes from '../config/constants/routes.js'

export const routes = [
    // {
    //     path: pageRoutes.insurama,
    //     component: () => import(/* webpackChunkName: "MainComponentHome" */ "../components/MainComponentHome.vue"),
    //     children: [
    //         { name: 'insurama', path: '', component: () => import(/* webpackChunkName: "MainComponentHome" */ "../pages/HomeContent.vue") },
    //     ],
    //     props: true
    // },
    // {
    //     path: pageRoutes.insurama,
    //     component: () => import(/* webpackChunkName: "MainComponentHome" */ "../components/MainCmsComponentHome.vue"),
    //     children: [
    //         { name: 'legal-warning2', path: pageRoutes.legalwarning2, component: () => import(/* webpackChunkName: "MainComponentHome" */ "../pages/LegalWarning.vue") },
    //         { name: 'privacity2', path: pageRoutes.privacity2, component: () => import(/* webpackChunkName: "MainComponentHome" */ "../pages/Privacity.vue") },
    //         { name: 'cookies2', path: pageRoutes.cookies2, component: () => import(/* webpackChunkName: "MainComponentHome" */ "../pages/Cookies.vue") }
    //     ],
    //     props: true
    // },
    {
        path: pageRoutes.initial,
        component: () => import(/* webpackChunkName: "MainComponent" */ "../components/MainComponent.vue"),
        children: [
            { name: 'home', path: '', component: () => import(/* webpackChunkName: "MainComponent" */ "../pages/Home.vue")},
            { name: 'contact', path: pageRoutes.contact, component: () => import(/* webpackChunkName: "MainComponent" */ "../pages/Contact.vue")},
            { name: 'claim', path: pageRoutes.claim, component: () => import("../pages/Claim.vue")},
            { name: 'map', path: pageRoutes.map, component: () => import(/* webpackChunkName: "MainComponent" */ "../pages/Map.vue") },
        ],
        props: true
    },
    {
        path: pageRoutes.initial,
        component: () => import(/* webpackChunkName: "MainCmsComponent" */ "../components/MainCmsComponent.vue"),
        children: [
            { name: 'legal-warning', path: pageRoutes.legalwarning, component: () => import(/* webpackChunkName: "MainCmsComponent" */ "../pages/LegalWarning.vue") },
            { name: 'contracting-conditions', path: pageRoutes.contractingconditions, component: () => import(/* webpackChunkName: "MainCmsComponent" */ "../pages/ContractingConditions.vue") },
            { name: 'privacity', path: pageRoutes.privacity, component: () => import(/* webpackChunkName: "MainCmsComponent" */ "../pages/Privacity.vue") },
            { name: 'cookies', path: pageRoutes.cookies, component: () => import(/* webpackChunkName: "MainCmsComponent" */ "../pages/Cookies.vue") }
        ],
        props: true
    },
    {
        path: pageRoutes.order,
        component: () => import(/* webpackChunkName: "Order" */ "../layouts/MainLayout.vue"),
        children: [{
                name: 'order',
                path: '',
                // component: Order,
                component: () => import(/* webpackChunkName: "Order" */ "../pages/Order.vue"),
            },
            {
                path: pageRoutes.ordererror,
                component: () => import(/* webpackChunkName: "Order" */ "../layouts/MainLayout.vue"),
                children: [{
                name: 'ordererror',
                    path: pageRoutes.ordererror,
                    component: () => import(/* webpackChunkName: "Order" */ "../pages/OrderError.vue"),
                }]
            },
            {
                path: pageRoutes.orderok,
                component: () => import(/* webpackChunkName: "Order" */ "../layouts/MainLayout.vue"),
                children: [{
                name: 'orderok',
                    path: pageRoutes.orderok,
                    component: () => import(/* webpackChunkName: "Order" */ "../pages/OrderOK.vue")
                }]
            }
        ]
    },
    {
        name: 'error',
        path: pageRoutes.error,
        component: () => import(/* webpackChunkName: "Error404" */ "../pages/Error.vue")
    },
    {
        path: '*',
        component: () => import(/* webpackChunkName: "Error404" */ "../pages/Error404.vue")
    }
]

export default routes