import { mutations } from '@/state/store.js'
import navLink from '@/config/constants/navLink.js'
import subdomain from './constants/subdomain.js'
import fixedDomains from '@/config/constants/fixedDomains.js'

export function setEnvConfig(host) {
    if (Object.values(fixedDomains).includes(host)) {
        return setLanding('', host, true)
    }
    const partsOfUrl = host.split('.');
    

    if(partsOfUrl.indexOf('localhost:8080') != -1){
        if(partsOfUrl.length == 1){
            console.log("vue app domain: " + process.env.VUE_APP_DOMAIN);
            return setLanding(process.env.VUE_APP_LANDING, partsOfUrl[0]);
            
        }else{
            return setLanding(partsOfUrl[0], partsOfUrl[1]);
        }
    }

    if (partsOfUrl[1] === "insurama") { //Redegal des2 environment
        console.log("Redegal des2 environment");
        return setLanding(partsOfUrl[0], partsOfUrl[1] + "." + partsOfUrl[2] + "." + partsOfUrl[3])
    } else if (['dev', 'pre', 'pro'].indexOf(partsOfUrl[1]) !== -1) {
        const landing = partsOfUrl.shift()
        const domain = partsOfUrl.join('.')
        return setLanding(landing, domain)
    } else if (process.env.VUE_APP_LANDING && process.env.VUE_APP_DOMAIN) {
        return setLanding(process.env.VUE_APP_LANDING, process.env.VUE_APP_DOMAIN)
    }
}

function setLanding(subdomain, domain, fixedSubdomain = false) {
    let currentLanding
    if (fixedSubdomain === true) {
        mutations.setCurrentLanding(Object.keys(fixedDomains).find(key => fixedDomains[key] === domain));
        setNavigationLinks(fixedDomains[domain], false)
        return true;
    } else {
        currentLanding = mutations.setCurrentLanding(subdomain);
        
        if (currentLanding !== '') {
            console.log(domain)
            setNavigationLinks(domain)
            return true;
        }
    }
    return false;
}

function setNavigationLinks(domain, hasSubdomain = true) {
    Object.keys(navLink).forEach(link => {
            navLink[link] = `${window.location.protocol}//${hasSubdomain === true ? subdomain[link] + '.' + domain : fixedDomains[link]}`
            
        })
        
}