const pageRoutes = {
    initial: '/',
    insurama: '/insurama',
    cookies: '/cookies',
    order: '/order',
    ordererror: '/order-error',
    orderok: '/order-ok',
    error: '/error'
}

if (process.env.VUE_APP_COUNTRY_CODE == 'it') {
    pageRoutes['contact'] = '/contatto'
    pageRoutes['claim'] = '/reclami'
    pageRoutes['map'] = '/mappa'
    pageRoutes['legalwarning'] = '/avviso-legale'
    pageRoutes['contractingconditions'] = '/condizioni-generali'
    pageRoutes['privacity'] = '/privacy'

} else if (process.env.VUE_APP_COUNTRY_CODE == 'pt') {
    pageRoutes['contact'] = '/contacto'
    pageRoutes['map'] = '/mapa'
    pageRoutes['legalwarning'] = '/aviso-legal'
    pageRoutes['contractingconditions'] = '/condicoes-gerais'
    pageRoutes['privacity'] = '/privacidade'
} else {
    pageRoutes['contact'] = '/contacto'
    pageRoutes['map'] = '/mapa'
    pageRoutes['legalwarning'] = '/aviso-legal'
    pageRoutes['contractingconditions'] = '/condiciones-generales'
    pageRoutes['privacity'] = '/privacidad'
}

module.exports = pageRoutes