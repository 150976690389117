export function module97(iban) {
    var parts = Math.ceil(iban.length / 7);
    var remainer = "";

    for (var i = 1; i <= parts; i++) {
        remainer = String(parseFloat(remainer + iban.substr((i - 1) * 7, 7)) % 97);
    }

    return remainer;
}

export function getnumIBAN(letter) {
    var ls_letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return ls_letters.search(letter) + 10;
}

export function isItWorkingHours() {
    var d = new Date()
    var hours = d.getHours()
    var day = d.getDay()
    //Spain
    /*return (day >= 1 && day <= 5 && hours >= 10 && hours < 20) ||
        (day >= 6 && day <= 7 && hours >= 10 && hours < 18)*/

    if (process.env.VUE_APP_COUNTRY_CODE == 'it') {
        return (day >= 1 && day <= 5 && hours >= 9 && hours < 19)
    } else if (process.env.VUE_APP_COUNTRY_CODE == 'pt') {
        return (day >= 1 && day <= 7 && hours >= 10 && hours < 21)
    } else {
        return (day >= 1 && day <= 5 && hours >= 9 && hours < 19)
    }
}