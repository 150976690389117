/* Axios */
import axios from 'axios'
import ApiUrls from '@/config/constants/apiUrls.js'

/* Api insurama */
import { Api } from '@insurama/api-client'
var sb = new Api({ apiUrl: process.env.VUE_APP_INSURAMA_API_URL })

import i18n from '@/i18n.js'

// Functions
export async function getStores(params) {
    return await sb.getStores(params)
        .then(response => {
            return response.data.stores
        })
        .catch(function(err) {
            console.error(err)
        })
}

export async function getAllProducts() {
    return await sb.getProducts()
        .then(data => data.data)
        .then(function(data) {
            return data
                .map(function(item) {
                    //item.name = item.name.split(' ')[0]
                    /*item.coverages = Object.keys(coverages).map(function(i) {
                        var current = coverages[i]
                        return {
                            coverage_id: i,
                            label: current.label,
                            // eslint-disable-next-line eqeqeq
                            included: item.coverages.reduce(function(acc, item) { return acc || item.pivot.coverage_id == i }, false)
                        }
                    })*/
                    return item
                })
        })
        .then(function(data) {
            return data
        })
        .catch(function(err) {
            console.log(err)
        })
}

export async function getPolicy(identifier) {
    return await sb.getPolicy(identifier)
        .then(response => {
            return response.data
        })
        .catch(() => [])
}

export async function getPolicyPrice(params) {
    console.log(process.env.VUE_APP_INSURAMA_API_URL)
    return await sb.getPolicyPrice(params)
        .then(data => data.data)
        .then(function(data) {
            var price = data['price_per_payment'].toFixed(2).replace(".", ",");
            return price
        })
        .catch(function(err) {
            console.log(err)
            return -1
        })
}

export async function getDeviceModels() {
    return await axios.get(ApiUrls.DEVICE_MODELS)
        .then((response) => {
            return response.data
        })
        .catch(function(err) {
            console.error(err)
        })
}

export async function createOrUpdateGenericItem(params) {
    return await sb.createOrUpdateGenericItem(params)
        .then(response => {
            console.log(response);
            return response.data
        })
        .catch(() => [])
}

export async function submitPolicy(params) {
    return await sb.createPolicy(params)
        .then(response => {
            console.log(response)
            if (response.status == 201) {
                return {
                    isValidPolicy: true,
                    policy: response.data.policy,
                    form: response.data.form
                }
            } else {
                return {
                    isValidPolicy: false,
                    error: i18n.t('error_ocurred')
                }
            }
        })
        .catch(error => {
            return {
                isValidPolicy: false,
                error: error.message
            }
        })
}

export async function submitContact(params, config) {
    return await axios.post(ApiUrls.CONTACT_POST, params, config)
        .then(response => {
            if (response.status == 201) {
                return {
                    isValidContact: true
                }
            } else {
                return {
                    isValidContact: false,
                    error: i18n.t('error_ocurred')
                }
            }
        })
        .catch(error => {
            return {
                isValidContact: false,
                error: error.message
            }
        })
}
