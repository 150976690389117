import Vue from 'vue'

Vue.mixin({
    methods: {
        isValidCheckClass(checked, stepBtnClicked = false) {
            if (checked == false && stepBtnClicked) {
                return 'notValid'
            } else {
                return ''
            }
        },
        isValidPhoneClass(phone, stepBtnClicked = false) {
            if (phone.length == 0 && !stepBtnClicked) {
                return ''
            } else if (phone.length == 0) {
                return 'notValid'
            } else if (this.isValidPhone(phone)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidPriceClass(price, stepBtnClicked = false) {
            if (price != "") {
                var priceFloat = parseFloat(price)
                if (priceFloat <= 0) {
                    return 'notValid'
                } else if (stepBtnClicked) {
                    return 'valid'
                } else {
                    return ''
                }
            } else if (stepBtnClicked) {
                return 'notValid'
            } else {
                return ''
            }
        },
        isValidPurchaseDateClass(landing, date, product_id, stepBtnClicked = false) {
            if (date.length == 0 && !stepBtnClicked || !stepBtnClicked) {
                return ''
            } else if (date.length == 0) {
                return 'notValid'
            } else if (this.isValidPurchaseDate(landing, date, product_id)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidSelectedDeviceClass(listDevices, selectedDevice, incorrectDevice, stepBtnClicked = false) {
            if(listDevices === '' || selectedDevice === '' && !incorrectDevice && !stepBtnClicked){
                return '';
            }else if(!listDevices.includes(selectedDevice)){
                return 'notValid';
            }else if(listDevices.includes(selectedDevice)){
                return 'valid';
            }
        },
        isValidNifNieClass(nif, stepBtnClicked = false) {
            if (nif.length == 0 && !stepBtnClicked) {
                return ''
            } else if (nif.length == 0) {
                return 'notValid'
            } else if (this.isValidNifNie(nif)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidTextClass(text, length = 0, stepBtnClicked = false) {
            if (text.length == 0 && !stepBtnClicked) {
                return ''
            } else if (text.length == 0) {
                return 'notValid'
            } else if (this.isValidTextField(text, length)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidEmailClass(email, stepBtnClicked = false) {
            if (email.length == 0 && !stepBtnClicked) {
                return ''
            } else if (email.length == 0) {
                return 'notValid'
            } else if (this.isValidEmail(email)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidCardNumberClass(cardNumber, stepBtnClicked = false) {
            if (cardNumber.length == 0 && !stepBtnClicked) {
                return ''
            } else if (cardNumber.length == 0) {
                return 'notValid'
            } else if (this.isValidCardNumber(cardNumber)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidExpirationDateMonthCardClass(month, stepBtnClicked = false) {
            if (month.length == 0 && !stepBtnClicked) {
                return ''
            } else if (month.length == 0) {
                return 'notValid'
            } else if (this.isValidExpirationDateMonthCard(month)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidExpirationDateYearCardClass(year, stepBtnClicked = false) {
            if (year.length == 0 && !stepBtnClicked) {
                return ''
            } else if (year.length == 0) {
                return 'notValid'
            } else if (this.isValidExpirationDateYearCard(year)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidCVVCardClass(cvv, stepBtnClicked = false) {
            if (cvv.length == 0 && !stepBtnClicked) {
                return ''
            } else if (cvv.length == 0) {
                return 'notValid'
            } else if (this.isValidCVVCard(cvv)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidIBANClass(iban, stepBtnClicked = false) {
            if (iban.length == 0 && !stepBtnClicked) {
                return ''
            } else if (iban.length == 0) {
                return 'notValid'
            } else if (this.isValidIBAN(iban)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidAcceptanceTypeClass(acceptanceType, stepBtnClicked = false) {
            if (acceptanceType == 0 && !stepBtnClicked) {
                return ''
            } else if (acceptanceType == 0) {
                return 'notValid'
            } else {
                return ''
            }
        },
        isSpain(){
            if (process.env.VUE_APP_COUNTRY_CODE == 'es'){
                return true;
            }
            return false;
        }
    }
})